<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Level vs User Count</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-combobox
        v-model="timeSelect"
        :items="timeItems"
        :loading="$store.state.progress"
        item-value="id"
        item-text="value"
        @change="initialize"
        hide-details
        label="Time"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>

      <v-combobox
        v-model="typeSelect"
        :items="gameType"
        :loading="$store.state.progress"
        item-value="key"
        item-text="value"
        @change="initialize"
        hide-details
        label="Mode Type"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>

      <v-combobox
        v-model="pageItemSelect"
        :items="pageItems"
        item-value="id"
        item-text="value"
        @change="initialize"
        hide-details
        label="Page"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn color="primary" dark @click="initialize" class="mb-2 ma-2"
        >Search</v-btn
      >
    </v-toolbar>
    <v-data-table
      :headers="headerList"
      :items="$store.state.modeLogDetails.all"
      :items-per-page="pageItemSelect.id"
      hide-default-footer
      :loading="loading"
      :options.sync="options"
      loading-text="Loading... Please wait"
      class="elevation-1"
    >
      <!-- <template v-slot:item.cd="{ item }">
        <div>{{ new Date(item.cd * 1000) | formatDate }}</div>
      </template>
      <template v-slot:item.bl="{ item }">
        <div>{{ item.bl | formatMoney }}</div>
      </template> -->
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="currentPage"
        @input="pageClick"
        :total-visible="20"
        :length="$store.state.gameLogs.all.totalPage"
      ></v-pagination>
    </div>
  </div>
</template>
<script>
//import VJsoneditor from "v-jsoneditor";
import "@/assets/css/site.css";
import gameType from "@/lib/gameType";

export default {
  components: {},

  name: "LevelUserLogCard",
  props: {
    search: {
      type: String,
    },
  },
  data() {
    return {
      dataItems: [],
      dataItemsToDisplay: [],
      pageCount: 0,
      timeSelect: { id: -1, value: "All" },
      typeSelect: { key: -999, value: "All" },
      pageItemSelect: { id: 50, value: "50" },
      loading: false,
      currentPage: 1,
      gameType: gameType,
      options: {},
      detail: {},
      jsonOptions: {
        mode: "code",
      },
      pagination: {
        sortBy: "name",
      },
      timeItems: [
        { id: -1, value: "All" },
        { id: 60 * 30, value: "Last 30 minutes" },
        { id: 60 * 60, value: "Last 1 hour" },
        { id: 60 * 60 * 24, value: "Last 24 hours" },
        { id: 60 * 60 * 24 * 7, value: "Last 7 Days" },
        { id: 60 * 60 * 24 * 30, value: "Last 30 Days" },
        { id: 60 * 60 * 24 * 90, value: "Last 90 Days" },
      ],

      pageItems: [
        { id: 50, value: "50" },
        { id: 100, value: "100" },
        { id: 250, value: "250" },
        { id: 500, value: "500" },
        { id: 1000, value: "1000" },
        { id: 2000, value: "2000" },
        { id: 5000, value: "5000" },
        { id: 10000, value: "10000" },
      ],
      headerList: [
        { text: "Level", value: "level" },
        { text: "Player Count", value: "playerCount" },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        if (this.options.sortBy.length > 0) this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    pageClick(a) {
      this.currentPage = a;
      this.initialize();
    },

    groupBy(a, keyFunction) {
      const groups = {};
      a.forEach(function(el) {
        const key = keyFunction(el);
        if (key in groups === false) {
          groups[key] = [];
        }
        groups[key].push(el);
      });
      return groups;
    },

    async initialize() {
      this.loading = true;
      await this.$store.dispatch("modeLogDetails/getAllLevelUserDetails", {
        time: this.time,
        size: this.pageItemSelect.id,
        modeType: this.typeSelect.key,
        page: this.currentPage - 1,
        date: this.timeSelect.id,
        options: this.options,
      });
      this.loading = false;
      this.dataItems = this.$store.state.gameLogs.all.data;
      const byLevel = this.groupBy(this.dataItems, (x) => x.l);
      this.dataItemsToDisplay = Object.keys(byLevel).map((level) => {
        const byUser = this.groupBy(byLevel[level], (x) => x.u);
        //const sum = byLevel[l].reduce((acc, x) => acc + x.Value, 0)
        return {
          l: level,
          pc: Object.keys(byUser).length,
          lc: this.dataItems.length,
        };
      });

      // eslint-disable-next-line no-console
      console.log(this.dataItemsToDisplay);
      // eslint-disable-next-line no-console
      console.log(this.dataItems);
    },
  },

  async mounted() {
    await this.initialize();
  },
};
</script>

<style>
.card-p {
  width: 142px;
  height: 155px;
  background-size: 142px 155px;
  background-repeat: no-repeat;
  border-radius: 9.5px;
  margin-right: 12px;
  margin-top: 2px;
  margin-bottom: 2px;
}
.lime-card {
  background-image: url(https://bundle.slotbase.net/assets/img/green.png);
}
.green-card {
  background-image: url(https://bundle.slotbase.net/assets/img/green.png);
}
.red-card {
  background-image: url(https://bundle.slotbase.net/assets/img/red.png);
}
.orange-card {
  background-image: url(https://bundle.slotbase.net/assets/img/orange.png);
}
.v-tooltip__content {
  opacity: 1 !important;
}
.jv-container {
  background: #0000005c !important;
}
.jsoneditor-field {
  color: #b7b3c3 !important;
}
a.jsoneditor-value.jsoneditor-url,
div.jsoneditor-value.jsoneditor-url {
  color: #00cfff !important;
}
div.jsoneditor-value.jsoneditor-array,
div.jsoneditor-value.jsoneditor-object {
  color: #b7abab !important;
}
.ace-jsoneditor .ace_variable,
.ace-jsoneditor .ace_gutter-cell {
  color: #fff !important;
}
div.jsoneditor-value.jsoneditor-string {
  color: #00cfff;
}
.ace-jsoneditor .ace_scroller,
.ace-jsoneditor .ace_gutter {
  background: #000 !important;
}
</style>
