<template>
  <level-user-log-card />
</template>
<script>
import LevelUserLogCard from "./components/LevelUserLogCard.vue";
export default {
  components: {
    LevelUserLogCard,
  },
};
</script>
